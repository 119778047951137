<template>
 <div class="v_money_detail g-flex-column">
   <head-back :isBack="true">
    <template>
      <span>收益明细</span>
    </template>
    <template v-slot:returnRight>
      <i class="iconfont iconrili" @click="timeSelectClick"></i>
    </template>
  </head-back>
  <!-- 日历 -->
  <van-calendar :default-date="defultDate" :allow-same-day="true" color="#0059C6" type="range" v-model="show" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" />

  <div class="v-money-content">
    <van-list class="v-money-content-list" v-show="moneyList != 0" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
      <div class="v-money-content-list-item" v-for="(item,index) in moneyList" :key="index">
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">编号:</span>
          <span class="v-money-content-list-item-line-value">{{item.id}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">订单编号: </span>
          <span class="v-money-content-list-item-line-value">{{item.user_task_order_id}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">{{item.type == 1 ? '买家ID': '商家ID'}}:</span>
          <span class="v-money-content-list-item-line-value">{{item.user.id}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">手机号:</span>
          <span class="v-money-content-list-item-line-value">{{item.user.mobile}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">抽佣点数:</span>
          <span class="v-money-content-list-item-line-value v-money-content-list-item-line-value-money">{{item.rate}}%</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">推广所得利润:</span>
          <span class="v-money-content-list-item-line-value v-money-content-list-item-line-value-balance">{{item.money}}元</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">时间: </span>
          <span class="v-money-content-list-item-line-value">{{formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
        </div>
      </div>
    </van-list>

    <NoList v-show="moneyList.length == 0"/> 

  </div>
 </div>
</template>

<script>
import HeadBack from '../../components/HeadBack.vue'
import { PAGE, LIMIT } from '@/utils/constants.js'
import { apiGetSpreadItemList } from '@/utils/api.js'
import { formatDate } from '@/utils/index.js';
// 一天的时间戳
var oneDayTamp = 24 * 60 * 60 * 1000
// 今天是这个月第几号
var days = new Date().getDate() - 1
// 默认时间是这个月1号到现在
var defultDate = [new Date(new Date().getTime() - (days * oneDayTamp)), new Date()]
// var defultDateTimeTap = [parseInt(defultDate[0].getTime() / 1000), parseInt(defultDate[1].getTime() / 1000)]
export default {
  components: { HeadBack },
  data() {
    return {
      show: false,
      minDate: new Date(2004, 0, 1),
      maxDate: new Date(),
      num: 10,
      moneyList: [],
      defultDate,

      form: {
        page: PAGE,
        limit: LIMIT,
        date_time: []
      },

      loading: false,
      finished: false
    }
  },
  created() {
    this.apiGetSpreadItemListHandel()
  },
  methods: {
    formatDate,
    // 获取资金明细列表
    async apiGetSpreadItemListHandel() {
      const { success, data } = await apiGetSpreadItemList(this.form)
      if(!success) return
      this.loading = false
      this.moneyList = this.moneyList.concat(data.list)
      this.finished = data.list.length < this.form.limit
      console.log(data)
    },
    onLoad() {
      console.log('onLoad')
    },
    onConfirm(date) {
      this.form.date_time = [parseInt(date[0].getTime() / 1000), parseInt(date[1].getTime() / 1000)]
      this.show = false
      this.page = 1
      this.moneyList = []
      this.loading = true
      this.finished = false
      this.apiGetSpreadItemListHandel()
    },
    timeSelectClick() {
      this.show = true
    }
  }
}
</script>

<style lang='scss'>
.v_money_detail {
  height: 100%;
  overflow: auto;
  .iconrili {
    font-size: 20px;
    color: $main_color;
    height: 100%;
    padding: 0 0 0 8px;
  }
  .v-money-content {
    flex: 1;
    overflow: auto;
    background: #F1F1F1;
    .v-money-content-list {
      height: 100%;
      padding: 8px 16px;
      background: #F1F1F1;
      overflow: auto;
      .v-money-content-list-item {
        background: $white;
        margin-top: 14px;
        border-radius: 8px;
        padding: 20px 18px;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        .v-money-content-list-item-line {
          padding-top: 18px;
          font-size: 13px;
          color: #0B0B0B;
          &:nth-of-type(1) {
            padding-top: 0;
          }
          .v-money-content-list-item-line-title {
            min-width: 40px;
          }
          .v-money-content-list-item-line-value {
            flex: 1;
            padding-left: 10px;
            word-break: break-all;
            &.v-money-content-list-item-line-value-money {
              color: #2473EF;
              font-size: 18px;
              font-weight: bold;
            }
            &.v-money-content-list-item-line-value-balance {
              color: #FF6A00;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>